import { AboutContainer, ContactContainer, HomeContainer, ResumeContainer } from 'pages';
import { FC } from 'react';
import { motion } from 'framer-motion';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';

const PageLayout = ({ children }) => children;

const pageVariants = {
	'initial': {
		opacity: 0,
	},
	'in': {
		opacity: 1,
	},
	'out': {
		opacity: 0,
	},
};

const pageTransition = {
	type: 'tween',
	ease: 'linear',
	duration: 0.5,
};

const PageContentWrapper = () => {
	const { pathname } = useLocation();

	return (
		<PageLayout>
			<motion.div key={pathname} initial='initial' animate='in' variants={pageVariants} transition={pageTransition}>
				<Outlet />
			</motion.div>
		</PageLayout>
	);
};

export const PageContent: FC = () => (
	<Routes>
		<Route element={<PageContentWrapper />}>
			<Route path='/' element={<HomeContainer />} />
			<Route path='/about' element={<AboutContainer />} />
			<Route path='/resume' element={<ResumeContainer />} />
			<Route path='/contact' element={<ContactContainer />} />
			<Route path='*' element={<HomeContainer />} />
		</Route>
	</Routes>
);
