import { FC } from 'react';
import { formatDate } from 'utilities';
import { IEducationData } from 'interfaces';

export const Education: FC<IEducationData> = ({
	award,
	completion,
	content,
	course_type,
	location,
	provider,
	title,
}): JSX.Element => (
	<div
		className={`education eduType-${course_type}`}
		data-testid='education'
		itemScope={true}
		itemType='http://schema.org/CourseInstance'
	>
		<div className='eduDate' data-testid='eduDate' itemProp='endDate' data-actualdate={completion}>
			{formatDate(completion, true)}
		</div>
		<div
			className='eduEvent'
			data-testid='eduEvent'
			itemProp='subEvent'
			dangerouslySetInnerHTML={{ __html: title.rendered }}
		/>
		<div className='eduProvider' data-testid='eduProvider' itemProp='organizer'>
			<a href={location} target='_blank' data-testid='eduProviderLink' rel='noopener noreferrer'>
				{provider}
			</a>
			{award && (
				<span className='eduAward' data-testid='eduAward' itemProp='award'>
					{award}
				</span>
			)}
		</div>
		<div
			className='eduDescription'
			data-testid='eduDescription'
			itemProp='about'
			dangerouslySetInnerHTML={{ __html: content.rendered }}
		/>
	</div>
);
