import { Education, Employer, Reference } from './';
import { FC, useState } from 'react';
import { IEducationData, IEmployerData, IPageData, IReferenceData, ITextKeys } from 'interfaces';
import { TEXT_KEYS } from 'data';
export interface IResumeProps extends IPageData {
	education: IEducationData[];
	work: IEmployerData[];
	references: IReferenceData[];
}

export const Resume: FC<IResumeProps> = ({ content, education, references, work }): JSX.Element => {
	const textKeys: ITextKeys = TEXT_KEYS;
	const [selectedItem, setSelectedItem] = useState('resumeSummary');

	const handleOptionChange = (evt): void => {
		setSelectedItem(evt.target.id);
	};

	return (
		<div>
			<div className='container accordionWrapper' itemScope={true} itemType='http://schema.org/ProfilePage'>
				<div id='summary'>
					<input
						checked={selectedItem === 'resumeSummary'}
						id='resumeSummary'
						name='resumeAccordion'
						onChange={handleOptionChange}
						type='radio'
					/>
					<label htmlFor='resumeSummary'>{textKeys.resume.overview}</label>
					<article className='smallPane'>
						<p dangerouslySetInnerHTML={{ __html: content.rendered }} />
					</article>
				</div>
				<div id='employerList'>
					<input
						checked={selectedItem === 'resumework'}
						id='resumework'
						name='resumeAccordion'
						onChange={handleOptionChange}
						type='radio'
					/>
					<label htmlFor='resumework'>{textKeys.resume.employers}</label>
					<article>
						{work.map((employer: IEmployerData) => (
							<Employer
								key={employer.id}
								{...employer}
								presentText={textKeys.global.present}
								additionalText={textKeys.global.additional}
							/>
						))}
					</article>
				</div>
				<div id='educationList'>
					<input id='resumeEducation' name='resumeAccordion' type='radio' onChange={handleOptionChange} />
					<label htmlFor='resumeEducation'>{textKeys.resume.education}</label>
					<article>
						{education.map((cert: IEducationData) => (
							<Education key={cert.id} {...cert} />
						))}
					</article>
				</div>
				<div id='referenceList'>
					<input
						checked={selectedItem === 'resumeReferences'}
						id='resumeReferences'
						name='resumeAccordion'
						onChange={handleOptionChange}
						type='radio'
					/>
					<label htmlFor='resumeReferences'>{textKeys.resume.references}</label>
					<article>
						{references.map((ref: IReferenceData) => (
							<Reference key={ref.id} {...ref} />
						))}
					</article>
				</div>
			</div>
		</div>
	);
};
