import * as LogStyle from 'utilities';
import { checkHTTPStatus } from './StatusCodes';
import { IDataMethod, IFetchHeader, IKeyValuePair } from 'interfaces';

const $htmlElement: HTMLElement = document.getElementsByTagName('html')[0];

export const BASE_API_PATH: string = 'data/'; // 'http://davegillem.com/data/'; // 'http://api.davegillem.com/wp-json/wp/v2/';
export const DEFAULT_HEADERS: IFetchHeader = new Headers({
	'Content-Type': 'application/json; charset=utf-8;',
});

/**
 * Builds the actual endpoint string by replacing variable placeholders in the passed in endpoint with matching key/value found
 * in the params object (if found). Vraiables are indicated by the {variable} portion of the endpoint string (when applicable).
 * The query string is created based on the map provided, treating each key as a query parameter name,
 * and each value as the query parameter value. This performs URI encoding as needed for the parameter values
 */
export const buildEndpoint = (endpoint: string, params: IKeyValuePair, buildQueryString?: boolean): string => {
	let apiCall: string = endpoint;
	const result: string[] = [];

	Object.keys(params).forEach((key: string) => {
		const propVar: string = `{${key}}`;
		const hasVar: boolean = apiCall.includes(propVar);

		apiCall = apiCall.replace(propVar, encodeURIComponent(params[key] as string | number | boolean));
		if (hasVar) {
			delete params[key];
		} else if (buildQueryString) {
			result.push(`${key}=${encodeURIComponent(params[key] as string | number | boolean)}`);
		}
	});
	if (result.length) {
		apiCall = `${apiCall}?${result.join('&')}`;
	}
	return apiCall;
};

/**
 * Converts the successful return to JSON format prior to returning back to the callee
 * If there is no content present in the response the original response object is returned for the callee to check the status
 * NOTE: this may need to be expanded with further statuses
 */
export const parseJSON = (response) => {
	if (checkHTTPStatus(response.status).empty) {
		return response;
	}
	return response.json();
};
/**
 * Checks to make sure the return object was a success prior to passing it back to the callee
 */
export const checkStatus = (response: Response): Response | void => {
	if (response.ok) {
		const respType: string | null = response.headers.get('content-type');

		if (respType && (respType.includes('application/json') || respType.includes('application/scim+json'))) {
			return response;
		}
		if (checkHTTPStatus(response.status).empty) {
			return response;
		}
		try {
			throw new TypeError("Oops, we haven't got JSON!");
		} catch (err) {
			console.log("Oops, we haven't got JSON!", err);
		}
	} else {
		throw new Error(response.statusText);
	}
};

/**
 * Takes the data passed in and makes an AJAX call. The return is then converted to JSON and passed back to the originating callee
 * A query string is constructed from the data object as needed. Dynamic API parameter replacement is also handled using the data object and endpoint
 */
export const getData = (api: IDataMethod, data: IKeyValuePair = {}) => {
	const { headers, method }: { headers: IFetchHeader; method: string } = api;
	const hasBody: boolean = method === 'GET' || method === 'DELETE';
	const endpoint: string = buildEndpoint(api.endpoint, data, hasBody);

	console.group(`%c${method} - ${api.endpoint} `, LogStyle.API);
	console.log('CALLING - ', endpoint);
	console.log('PASSING - ', data);
	console.groupEnd();
	$htmlElement.classList.add('datalLoading');

	return fetch(endpoint, {
		body: hasBody ? undefined : JSON.stringify(data),
		cache: 'no-cache',
		headers: headers,
		method: method,
	})
		.then(checkStatus)
		.then(parseJSON)
		.then((response) => {
			$htmlElement.classList.remove('datalLoading');
			console.group(`\t%cRESPONSE FOR : ${api.endpoint}`, LogStyle.SUCCESS);
			console.log(`\tDATA FOR ${api.endpoint} : `, response || 'API RETURNS NO DATA HERE');
			console.groupEnd();

			return response;
		});
};
/**
 * Constructs the default API object used in the API call variables
 */
export const getApiCall = (endpoint: string, optional?: { headers?: IFetchHeader; method?: string }): IDataMethod => ({
	endpoint: endpoint,
	headers: optional?.headers || DEFAULT_HEADERS,
	method: optional?.method || 'GET',
});
