import { DataContext } from 'App';
import { FC, useState } from 'react';
import { ISocialAccountData } from 'interfaces';
import { NavItem, SiteLogo, SocialItem } from 'components';
interface INavbarProps {
	setMenuState: (isActive: boolean) => void;
}

export const Navbar: FC<INavbarProps> = ({ setMenuState }): JSX.Element => {
	const [burgerActive, setBurgerActive] = useState(false);

	const toggleBurger = (): void => {
		const burgerState: boolean = burgerActive;

		setBurgerActive(!burgerState);
		setMenuState(!burgerState);
	};

	const checkMobileMenu = (): void => {
		if (burgerActive) {
			toggleBurger();
		}
	};

	return (
		<nav className='showNav navbar is-fixed-top' itemScope={true} itemType='http://schema.org/SiteNavigationElement'>
			<DataContext.Consumer>
				{(dataContext): JSX.Element => {
					const { landing, about, resume, contact } = dataContext.textKeys.navKeys;

					return (
						<>
							<div id='mobile-nav-icon' className={`burgerMenu${burgerActive ? ' open' : ''}`} onClick={toggleBurger}>
								<span />
								<span />
								<span />
							</div>
							<div id='appNavbar' className='' data-testid='nav-bar'>
								<NavItem path='/' label={landing} onClick={checkMobileMenu} />
								<NavItem path='/about' label={about} onClick={checkMobileMenu} />
								<SiteLogo href='/' id='dgLogo' onClick={checkMobileMenu} />
								<NavItem path='/resume' label={resume} onClick={checkMobileMenu} />
								<NavItem path='/contact' label={contact} onClick={checkMobileMenu} />

								<div className='appNavLink socialMenu'>
									<div className='has-dropdown is-hoverable'>
										<div className='navbar-dropdown is-boxed'>
											{dataContext.social.map(
												(link: ISocialAccountData): JSX.Element => (
													<SocialItem key={link.id} {...link} isMobile={true} />
												),
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					);
				}}
			</DataContext.Consumer>
		</nav>
	);
};
