import { AnimatedLogo } from 'components';
import { FC } from 'react';

interface IPageLoaderProps {
	loadingID: string;
}

export const PageLoader: FC<IPageLoaderProps> = ({ loadingID }: IPageLoaderProps): JSX.Element => (
	<div className='loading' data-testid='loading'>
		<AnimatedLogo id={loadingID} data-testid='animatedlogo' />
	</div>
);
