import { FC } from 'react';
import { FooterContainer, SocialContainer } from 'components';
import { PageContent } from 'pages';

interface IMainContainerProps {
	mobileNav: boolean;
}

export const MainContainer: FC<IMainContainerProps> = ({ mobileNav }) => (
	<div id='main-content' className={'content-wrapper' + (mobileNav ? ' open' : '')} itemProp='mainContentOfPage'>
		<section className='section'>
			<PageContent />
			<SocialContainer />
		</section>
		<FooterContainer />
	</div>
);
